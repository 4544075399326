


function Thanks(props){

    return(
        <>
            <p className="form-text-thanks">
                {props.text.thanksTitle}
            </p>        
        </>
    )
}

export default Thanks;